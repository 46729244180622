import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import { Toolbar, Typography, Tooltip, IconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const EnhancedTableToolbar = () => {

    const [loading, setLoading] = React.useState(false);
    const download = () => {
        setLoading(true);
        const date = new Date();
        const fileName = `kalkulacja_${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}_${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}.xlsx`;

        axios({
            url: '/db/GetExpiringSubscriptionsListXLS',
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    alert('Your access is not allowed.');
                } else {
                    console.error('Error downloading report:', error);
                }
                setLoading(false);
            });
    };

    return (
        <div>
        <Toolbar
            sx={{
                display: 'flex', // Flexbox
                justifyContent: 'space-between', // Rozdzielenie elementów po bokach
                alignItems: 'center', // Wyśrodkowanie w pionie
                gap: 2, // Odstęp między elementami
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                bgcolor: '#C5D3E8', // Kolor tła
            }}
        >
            {/* Napis po lewej stronie */}
            <Typography
                sx={{ flex: '0 0 auto' }} // Zapewnia brak rozciągania
                variant="h5"
                id="tableTitle"
                component="div"
            >
                <b>Legenda do tabeli</b>
            </Typography>

            {/* Napis i ikona po prawej stronie */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    
                <Typography
                    sx={{ flex: '0 0 auto', mr: 1 }} // Odstęp między napisem a ikoną
                    variant="h8"
                    component="div"
                >
                    <b>Pobierz kalkulację</b>
                </Typography>

                <Tooltip title="Pobierz kalkulację">
                    <IconButton onClick={download}>
                        <CloudDownloadIcon sx={{ color: 'red' }} />
                    </IconButton>
                </Tooltip>
            </div>
             
        </Toolbar>
        {/* LinearProgress - wskaźnik postępu */ }
    {
        loading && (
            <LinearProgress sx={{ width: '100%', mt: 0 }} />
        )
    }   
    
        </div>

    );
};




const Sync = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);

    const inputRef = useRef(null);
    const expiringSubscriptionsReportCalculation = async () => {
        try {
            // Pobieranie pliku XLSX w formacie binarnym
            const response = await axios.get("/db/GetExpiringSubscriptionsListXLS", {
                responseType: 'blob' // umożliwia pobranie jako Blob
            });

            // Generowanie dynamicznej nazwy pliku
            const date = new Date();
            const fileName = `zestawienie_${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}_${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}.xlsx`;

            // Tworzenie URL dla pobranego pliku i wyzwolenie pobierania
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // ustawiamy nazwę pliku
            document.body.appendChild(link);
            link.click();

            // Sprzątanie po pobraniu
            link.parentNode.removeChild(link);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("Error downloading the report:", error);
            }
        }
    };



    const buttonStyle = { width: '300px' }; // Stała szerokość przycisków
    
 //   const cont

    return (

        <TableContainer component={Paper} sx={{ width: '70%', margin: '20px auto 0' }}>
            <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ padding: 0 }}>
                                <EnhancedTableToolbar />
                            </TableCell>
                        </TableRow>
                    <TableRow sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08) }}>
                        <TableCell sx={{ width: '25%' }}><b>Kolumna</b></TableCell>
                        <TableCell sx={{ width: '75%' }}><b>Opis</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>              
                            <b>Klient</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            nazwa Klienta
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Produkt</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            nazwa produktu
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>BillingPlan</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            model zobowiązania, w którym została zakupiona dana subskrypcja:
                            <ul>
                                <li><b>M/M</b> – Zobowiązanie Miesięczne, Fakturowanie Miesięczne</li>
                                <li><b>R/M</b> – Zobowiązanie Roczne, Fakturowanie Miesięczne</li>
                                <li><b>R/R</b> – Zobowiązanie Roczne, Fakturowanie Roczne (Jednorazowe)</li>
                            </ul>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Data zakończenia</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            data wygaśnięcia subskrypcji
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Ilość</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            obecna ilość licencji danej subskrypcji
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Sugerowana nowa cena BCM (EUR netto)</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            propozycja nowej ceny dla klienta za 1 szt. subskrypcji. Sugerowana cena obliczona na podstawie dotychczasowego poziomu marży
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Narzut %</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            proponowany narzut. Narzut równy obecnie zastosowanemu do sprzedaży subskrypcji
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Marża %</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            proponowana marża. Marża równa obecnie zastosowanej do sprzedaży subskrypcji
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Nowa cena zakupu</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            aktualna cena zakupu dla Betacom
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Nowa cena zakupu (specjalna)</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            aktualna cena specjalna zakupu dla Betacom, jeśli została przyznana
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Nowa cena SRP</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            aktualna cena detaliczna sugerowana przez Microsoft
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Rabat %</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            wartość rabatu od aktualnej ceny SRP
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecna cena sprzedaży BCM</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            cena w jakiej obecnie sprzedajemy 1 szt. subskrypcji klientowi
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecny narzut %</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            aktualna wartość narzutu na sprzedawanej subskrypcji
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecna marża % </b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            aktualna wartość marży na sprzedawanej subskrypcji
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecna cena zakupu</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            cena zakupu dla Betacom w momencie uruchomienia subskrypcji Dotyczy głównie subskrypcji z zobowiązaniem rocznym z racji na gwarancje cenową
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecna cena zakupu (specjalna)</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            cena specjalna zakupu dla Betacom w momencie uruchomienia subskrypcji, jeśli był przyznany
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecna cena SRP</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            cena detaliczna sugerowana przez Microsoft w momencie uruchomienia subskrypcji. Dotyczy głównie subskrypcji z zobowiązaniem rocznym z racji na gwarancje cenową
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Obecny rabat %</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            wartość rabatu od obecnej ceny SRP 
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.025), padding: '3px 12px' }}>  
                            <b>Opiekun DH</b>
                        </TableCell>
                        <TableCell sx={{ padding: '3px 12px' }}>
                            opiekun klienta z Działu Handlowego
                        </TableCell>
                    </TableRow>

                    

                    
                </TableBody>
            </Table>
            </TableContainer>
 //   );

/*

        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Title>Do testów - nie synchronizować!</Title>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { customersSyncData() }}
            >
                Synchornizacja - Customers
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { productsSyncData() }}
            >
                Synchornizacja - Products
            </Button>
            <input
                ref={inputRef}
                type="text"
                id="productId"
                name="productId"
            />
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { productByIdSyncData() }}
            >
                Synchornizacja - Products by Id
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { subscriptionsSyncData() }}
            >
                Synchornizacja - Subscriptions
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { ordersSyncData() }}
            >
                Synchornizacja - Orders
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { ordersLimitSyncData() }}
            >
                Synchornizacja - Orders (last 200)
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { eventsMS() }}
            >
                MS EVENTS
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { expiringSubscriptionsReport() }}
            >
                Raport wygasających subskrypcji
            </Button>
        </Paper>*/

    );
};

export default Sync;
